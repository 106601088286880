@use './styles/_mixins.scss' as *;
.listing {
  &_heading {
    margin-top: -20px;
    margin-bottom: 55px;
    position: relative;
    z-index: 1;
    font-size: 14px;
    font-weight: var(--font-medium);

    @include breakpoint(large) {
      margin-bottom: 100px;
      font-size: 20px;
    }
    @include breakpoint(huge) {
      font-size: 24px;
    }

    strong {
      color: var(--color-primary);
      text-transform: capitalize;
    }
  }

  &_wrap {
    position: relative;
    padding: 0 rem(10) 0 rem(10);
    z-index: 4;

    @include breakpoint(medium) {
      padding: 0 rem(16) rem(20) rem(16);
    }

    @include breakpoint(large) {
      margin-top: -30px;
      padding: 0 rem(24) rem(20) rem(24);

      &_filtered {
        flex: 1 0 320px;
        position: sticky;
        top: 20px;
        height: 100%;
        overflow-y: auto;
        max-width: 320px;
      }

      &_shown {
        flex: 3 1 auto;
        margin-left: 20px;
      }
    }

    &_inventory {
      margin-top: -20px;
      @include breakpoint(large) {
        display: flex;
        gap: 50px;
        margin-top: -15px;
        max-width: 2200px;
      }
    }
  }
  &_list {
    padding-top: rem(32);
    @include breakpoint(medium) {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      justify-content: space-evenly;
      column-gap: 24px;
      flex: 1;
      max-width: 1165px;
      margin: auto;
    }
    @include breakpoint(large) {
      padding-top: 0;
      justify-content: flex-start;
      max-width: unset;
    }

    &_error {
      text-align: center;
      margin-top: rem(32);
      font-weight: var(--font-medium);
      @include breakpoint(large) {
        flex: 1;
        font-size: rem(24);
        position: absolute;
        left: 0;
        right: 0;
      }
    }
  }

  &_bottomText {
    color: white;
    padding: 20px 0;
    font-size: clamp(0.875rem, 0.8447rem + 0.1294vw, 1rem);

    p {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_empty {
    text-align: center;
    margin-top: rem(48);
    @include breakpoint(large) {
      font-size: rem(20);
    }
  }

  &_all_filters {
    position: relative;
    margin-top: -30px;
    z-index: 5;
    @include breakpoint(large) {
      position: absolute;
      top: 333px;
      right: 40px;
      max-width: 2200px;
      margin: 0 auto;
      left: 0;
      transform-origin: right;
      bottom: auto;
      padding: 0;
      margin-top: 0;
    }
    @include breakpoint(huge) {
      top: 433px;
    }
    @media (min-width: 2200px) {
      right: 80px;
    }
  }

  &_loading {
    position: relative;
    top: -50px;
    text-align: center;
    font-weight: var(--font-medium);
    font-size: rem(16);
    color: var(--color-white);
    background: var(--color-primary);
    margin: auto;
    width: 190px;
    border-radius: 100px;
    padding: 15px 5px;
    z-index: -1;

    &_stock {
      margin-bottom: 30px;
      @include breakpoint(large) {
        margin-bottom: 80px;
      }
    }
  }

  &_rentalsCTA {
    position: relative;
    text-align: center;
    top: -15px;
    z-index: 1;
    @include breakpoint(large) {
      top: -55px;
    }
  }
}
